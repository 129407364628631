import {
  addMonths,
  isAfter,
  isBefore,
  startOfToday,
} from 'date-fns';
import { isWithinRange } from './date.js';

export function isCurrentlyRenewable(license, combination) {
  return license.renewable
    && license.costs !== null
    && ((isBefore(license.valid.end, addMonths(new Date(), 2))
        && isAfter(license.valid.end, addMonths(new Date(), -3)))
      || isAfter(startOfToday(), license.valid.end))
    // if combination is set, also remove this if we have a
    // valid other license at the moment for this sport.
    && (!combination || !combination.licenses.some(
      (l) => l.sport === license.sport && l.id !== license.id && isWithinRange(new Date(), l.valid),
    ));
}

export const latestLicensePerLicenseType = (licenses) => licenses.filter(
  (l) => !licenses.find(
    (ll) => ll.sport === l.sport
      && ll.licenseType === l.licenseType
      && isAfter(ll.valid.end, l.valid.end),
  ),
);
