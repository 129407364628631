import {
  computed,
  customRef,
  ref,
  toRaw,
  unref,
  watch,
} from 'vue';

import {
  debounce,
  smooth,
  throttle,
} from './throttle.js';
import { deepEqual } from './objects.js';

export function toModelValueRefs(modelValue, emit, fields) {
  // Convert an object modelValue into distinct observables for their properties

  if (!fields) {
    console.error('Optional modelValues should always have fields set');
    return {};
  }

  // Build a mapping for fields to watched refs of modelValue's value for that field.
  const fieldNames = fields || Object.keys(modelValue.value);
  const fieldsObject = Object.fromEntries(fieldNames.map((f) => [f, customRef((track, trigger) => ({
    get() {
      track();
      return (modelValue.value) ? modelValue.value[f] : undefined;
    },
    set(v) {
      if (modelValue.value && !deepEqual(toRaw(v), toRaw(modelValue.value[f]))) {
        // We had a value and it has changed
        emit('update:model-value', { ...modelValue.value, [f]: toRaw(v) });
        trigger();
      } else if (!modelValue.value && !!v) {
        // We were an empty modelValue, and now we're setting something
        emit('update:model-value', { [f]: v });
        trigger();
      }
    },
  }))]));

  // Proxy it so we can return a ref to undefined if the field is not set.
  return new Proxy(fieldsObject, {
    get(target, name) {
      const value = target[name];
      return (value === undefined) ? ref(undefined) : value;
    },
  });
}

export function toModelValueRef(modelValue, emit, name = 'model-value') {
  return customRef((track, trigger) => ({
    get() {
      track();
      return (modelValue.value) ? modelValue.value : undefined;
    },
    set(v) {
      if (modelValue.value !== v) {
        // We had a value and it has changed
        emit(`update:${name}`, v);
        trigger();
      } else if (!modelValue.value) {
        // We were an empty modelValue, and now we're setting something
        emit(`update:${name}`, v);
        trigger();
      }
    },
  }));
}

export function filteredObjectRef(objectRef, fields) {
  // object ref which only triggers when a field has changed
  return customRef((track, trigger) => ({
    get() {
      track();
      return objectRef.value;
    },
    set: (value) => {
      // eslint-disable-next-line no-param-reassign
      objectRef.value = value;
      if ((!value && objectRef.value)
        || fields.some((f) => objectRef.value && objectRef.value[f] !== value[f])) {
        // We had a value and it has changed
        trigger();
      }
    },
  }));
}

export function observedRef(observed, getter, defaultValue, setter) {
  // ref() which gets reset every time a watched computed is updated
  const codeValue = ref(getter(observed.value) || defaultValue);

  watch(observed, (v) => {
    const newValue = getter(v);
    if (newValue !== codeValue.value) {
      codeValue.value = newValue;
    }
  });

  return computed({
    get() {
      return codeValue.value;
    },
    set(v) {
      const newValue = (setter) ? setter(v) : v;
      if (newValue !== codeValue.value) {
        codeValue.value = newValue;
      }
    },
  });
}

export function observedIdRef(observed, getter, defaultValue) {
  // ref() which gets reset every time a watched computed is updated and its id changed
  const codeValue = ref(getter(observed.value) || defaultValue);

  watch(observed, (n, o) => {
    const newValue = getter();
    if (!!n !== !!o || n?.id !== o?.id) {
      codeValue.value = newValue;
    }
  });

  return codeValue;
}

export function triggeredRef(observed, getter) {
  // ref() which gets reset the first time a watched computed is updated

  const defaultValue = getter(observed.value, undefined);
  const codeValue = ref(defaultValue);

  const unwatch = watch(observed, (nv, ov) => {
    codeValue.value = getter(nv, ov);
    if (codeValue.value) {
      unwatch();
    }
  });

  return computed({
    get() {
      return codeValue.value;
    },
    set(v) {
      codeValue.value = v;
    },
  });
}

export function useDebouncedRef(initialValue, delay, immediate) {
  // ref() which will trigger – at most – every delay.
  const state = ref(initialValue);
  return customRef((track, trigger) => ({
    get() {
      track();
      return state.value;
    },
    set: debounce(
      (value) => {
        const changed = state.value !== value;
        state.value = value;
        if (changed) {
          trigger();
        }
      },
      delay,
      immediate,
    ),
  }));
}

export function useThrottledRef(initialValue, delay) {
  const state = ref(initialValue);
  return customRef((track, trigger) => ({
    get() {
      track();
      return state.value;
    },
    set: throttle(
      (value) => {
        state.value = value;
        trigger();
      },
      delay,
    ),
  }));
}

export function useSmoothRef(initialValue, delay) {
  const state = ref(initialValue);
  return customRef((track, trigger) => ({
    get() {
      track();
      return state.value;
    },
    set: smooth(
      (value) => {
        state.value = value;
        trigger();
      },
      delay,
    ),
  }));
}

export function useStoredRef(name, initialValue) {
  const key = `localRef:${name}`;

  try {
    const storedValue = localStorage.getItem(key);
    const state = ref((storedValue) ? JSON.parse(storedValue) : initialValue);
    return customRef((track, trigger) => ({
      get() {
        track();
        return state.value;
      },
      set(value) {
        if (value !== undefined) {
          localStorage.setItem(key, JSON.stringify(value));
        } else {
          localStorage.removeItem(key);
        }
        state.value = value;
        trigger();
      },
    }));
  } catch {
    // Most probably a SecurityError because of browser restrictions,
    // so, we treat it as a normal ref.
    return ref(initialValue);
  }
}

export function deepUnref(o) {
  if (!o) {
    return o;
  }

  if (o instanceof Date) {
    return o;
  }

  if (Array.isArray(o)) {
    return o.map(deepUnref);
  }

  if (typeof o === 'object') {
    return Object.fromEntries(Object.entries(o).map(([k, v]) => [k, deepUnref(v)]));
  }

  return unref(o);
}

export function diffComputed(getter) {
  // pure computed which only updates when the computed is changed
  const watchedRef = computed(getter);
  const buffer = ref(watchedRef.value);
  watch(watchedRef, (n, o) => {
    if (!deepEqual(n, o)) {
      buffer.value = n;
    }
  });
  return computed(() => buffer.value);
}
